import { useRef, useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { t } from "../locale";
import { isMobile } from "react-device-detect";
import {
    captureSnapshot,
    CameraComponent,
    imageComparing,
    checkDocumentData,
    saveImage,
    createJob
} from "../helpers/helpers";
import { useNavigate } from "react-router-dom";


let countSelfCheck = 0
let selfImageData: string
let documentImageData: string
let selfImageDataAnalyze: string

function FaceIdentification() {

    const navigate = useNavigate()

    const [expiredPage, setExpiredPage] = useState(false)
    const [hasAccess, setHasAccess] = useState(true)
    const [facingMode, setFacingMode] = useState('environment')


    const [buttonDisabled, setButtonDisabled] = useState('')

    const [borderColor, setBorderColor] = useState('border-primary')
    const [textColor, setTextColor] = useState('text-primary')

    const [secondsLeft, setSecondsLeft] = useState(10)
    const [checkInProgress, setCheckInProgress] = useState(false)
    const [captureLabel, setCaptureLabel] = useState('text')

    const [captured, setCaptured] = useState(true)

    const [imgClass, setImgClass] = useState('')
    const [spaceHeight, setSpaceHeight] = useState('mt-5')
    const [blinkLabel, setBlinkLabel] = useState('')
    const [mobileTop, setMobileTop] = useState('')
    const [passport, setPassport] = useState(false)

    const [frontButtonLabel, setFrontButtonLabel] = useState('')

    const fileInputFace = useRef<HTMLInputElement | null>(null);

    const pathSegments = window.location.pathname.split("/")
    const companyId = pathSegments[1]
    const requestId = pathSegments[2]

    useEffect(() => {
        fetchData()
        checkDevice()

        if (isMobile) {
            setSpaceHeight('')
        }

    }, []);

    CameraComponent(facingMode, isMobile)

    const init = async () => {
        setTextColor('text-primary')
        if (isMobile) {
            countSelfCheck = 0
        }
        setBorderColor('text-primary')
        setBlinkLabel('')
        setCaptureLabel('')
        setSecondsLeft(10)
    }
    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyId: companyId,
                        requestId: requestId,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();

            if (data) {
                if (data.status === "EXPIRED") {
                    setExpiredPage(false);
                }
            }

            if (data.passport === 'true')
                setPassport(true)
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasAccess(false);
        }
    };

    const checkDevice = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        // const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
        // const isTablet = /(ipad|macintosh|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

        if (isMobile) {
            setImgClass('mt-4')
            setMobileTop('elipse-frame-mobile')
            setFrontButtonLabel(t('buttonFrontUploadMobile'))
        } else {
            setFrontButtonLabel(t('buttonFrontUpload'))
        }
    }

    const captureFace = async () => {

        setButtonDisabled('disabled')
        const video = document.getElementById("videoElement") as HTMLVideoElement

        if (!isMobile) {
            const countdown = setInterval(() => {
                setSecondsLeft(prevSeconds => {
                    if (prevSeconds <= 1) {
                        clearInterval(countdown);
                        return 0;
                    }
                    return prevSeconds - 1
                });
            }, 1000);
        }

        setTimeout(async () => {
            if (!isMobile) {

                setCaptured(false)
                const result = await captureSnapshot(
                    video,
                    "SELF",
                    selfImageData,
                    documentImageData
                );

                setBlinkLabel('blinking-label')
                saveImage(result.selfImageData, companyId, requestId, true, 'self')
                setCheckInProgress(true)
                setCaptureLabel(t('waitFace'))
                selfImageData = result.selfImageData

                setTimeout(async () => {
                    const capturedImageSelfElement = document.getElementById('capturedImageSelf') as HTMLImageElement
                    capturedImageSelfElement.src = 'data:image/png;base64,' + selfImageData
                }, 2000)

            }

            verify()

        }, 10000);
    }

    const resizeImage = (file: File, maxWidth: number, maxHeight: number, callback: (base64: string) => void) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                // Proveri proporcije i prilagodi veličinu
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                // Postavi canvas dimenzije
                canvas.width = width;
                canvas.height = height;

                // Iscrtaj sliku na canvas sa smanjenom rezolucijom
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(img, 0, 0, width, height);

                    // Konvertuj canvas nazad u Base64 format
                    const base64String = canvas.toDataURL('image/png'); // Može biti i 'image/png'
                    const imageData = base64String.replace(/^data:image\/png;base64,/, '');
                    callback(imageData);
                }
            };
        };

        reader.readAsDataURL(file);
    }

    const handleFaceUpload = () => {
        if (fileInputFace.current) {
            fileInputFace.current.click();
        }
    }

    const faceUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {


        let selfImageElement: HTMLImageElement | null = null;
        const file = event.target.files?.[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                const base64String = reader.result as string
                selfImageDataAnalyze = base64String.replace(/^data:image\/(jpg|jpeg|png);base64,/, '')
                saveImage(selfImageDataAnalyze, companyId, requestId, true, 'self')
            };
            reader.readAsDataURL(file);

            setBlinkLabel('blinking-label')
            setCaptured(false)
            resizeImage(file, 800, 600, async (resizedBase64) => {
                const base64String = resizedBase64.replace(/^data:image\/(jpg|jpeg|png);base64,/, '')
                selfImageData = base64String


                selfImageElement = document.getElementById("capturedImageSelf") as HTMLImageElement
                selfImageElement.src = ''

                selfImageElement.src = "data:image/png;base64," + resizedBase64


                setCheckInProgress(true)
                setCaptureLabel(t('waitFace'))
                setSecondsLeft(0)


                await verify()
            })


        }
    }

    const verify = async () => {

        try {
            // documentImageData = await fetchImage()
            if (getBase64FromLocalStorage('documentImage')) {
                documentImageData = getBase64FromLocalStorage('documentImage') as string
            }

            const data = await imageComparing(documentImageData, selfImageData, companyId, requestId)

            if (data.status === true) {
                // if (true) {
                setBorderColor('border-success')

                setTimeout(async () => {
                    setBlinkLabel('')
                    setTextColor('text-success')
                    setCaptureLabel(t('success'))
                    navigate(`/${companyId}/${requestId}/video`)
                }, 5000);
            } else {
                countSelfCheck++;
                if (countSelfCheck >= 2) {
                    if (isMobile) {
                        setBlinkLabel('')
                        setTextColor('text-danger')
                        setCaptureLabel(t('failedFace'))
                        setTimeout(() => {
                            navigate(`/${companyId}/${requestId}/si/${passport}/p`)
                        }, 5000)
                    } else {
                        setBlinkLabel('')
                        setTextColor('text-danger')
                        setCaptureLabel(t('failedFace'))
                        setTimeout(() => {
                            navigate(`/${companyId}/${requestId}/si/${passport}/p`)
                        }, 5000)
                    }
                    return;
                }
                setButtonDisabled('')
                setBorderColor('border-danger')
                setTextColor('text-danger')
                setCaptureLabel(t('fail'))
                setBlinkLabel('')

                setTimeout(() => {
                    init();
                }, 5000);
            }
        }
        catch (error) {
            setCaptureLabel('Error loading document image.')
            setButtonDisabled('')
        }
    }

    const getBase64FromLocalStorage = (key: string): string | null => {
        try {
            const base64 = localStorage.getItem(key);
            if (!base64) {
                console.warn('Base64 string not found.');
                return null;
            }
            return base64;
        } catch (error) {
            console.error('Error fetching Base64 string:', error);
            return null;
        }
    };

    return (
        <>
            <Container className="container-fluid px-sm-5">
                <Col className="col-12 px-0 bg-white d-flex d-sm-none sticky-top z-0">
                    <div className="d-flex flex-row flex-grow-1 align-items-between align-items-sm-start px-2 pe-3 pt-3">
                        <ul className="nav nav-pills flex-row flex-nowrap flex-shrink-1 flex-grow-1 mb-0 justify-content-between align-items-center"
                            id="menu">
                            <li className="nav-item">
                                <a href="index.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 1 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step2.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 2 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step3.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 3 Active.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step4.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 4 Next.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step5.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 5 Next.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Container>
            {hasAccess ? (
                <Container hidden={expiredPage} className={`overflow-hidden ${spaceHeight}`}>
                    <Row className="row overflow-auto">
                        <Col className={`col-12 col-sm-3 col-xl-2 px-0 bg-white d-none d-sm-flex sticky-top pt-4 left-sidebar ${spaceHeight}`}>
                            <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-0 pt-2 text-white">
                                <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
                                    id="menu">
                                    <li className="mb-4">
                                        <a href=""
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 1 Done.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-success">{t('personalData')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a href=""
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 2 Done.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-success">{t('documentVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a href=""
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 3 Active.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-primary">{t('faceVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a href=""
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 4 Next.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-gray-light">{t('videoVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a href=""
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 5 Next.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-gray-light">{t('accountVerification')}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col className={`d-flex flex-column h-sm-100 px-sm-5 ${spaceHeight}`}>
                            <main className={`row overflow-auto px-sm-5 position-relative `}>
                                <div className="col pt-4 pt-sm-5 text-center text-sm-start mt-n1">
                                    <h4 className="mb-4">{t('titleFaceVerification')}</h4>
                                    <h5 className="d-sm-none">&nbsp;</h5>
                                    <div className="row">
                                        {!isMobile &&
                                            <div className="col-7 col-sm-3 mx-auto mx-sm-0 text-center">
                                                <video className="border ${borderColor} border-3 ellipse-frame mb-2" id="videoElement" autoPlay muted></video>

                                                {(secondsLeft !== 0 && <span className="text-primary h5 me-4">{secondsLeft}</span>)}


                                            </div>
                                        }
                                        <div className="col-sm-4 ms-2 text-center align-content-center">
                                            <button className={`btn btn-primary rounded-pill mt-2 d-none d-sm-inline ${buttonDisabled}`}
                                                onClick={captureFace}
                                                style={{ textTransform: 'none' }}>{t('buttonFrontCapture')}</button>
                                            <p className="my-2 d-none d-sm-block fs-5">{t('or')}</p>
                                            <button className={`btn btn-primary rounded-pill mt-2 mt-sm-0 me-2"  ${buttonDisabled}`}
                                                onClick={handleFaceUpload}
                                                style={{ textTransform: 'none' }}>{frontButtonLabel}</button>
                                            <input
                                                type="file"
                                                accept="image/*;capture=camera"
                                                onChange={faceUpload}
                                                ref={fileInputFace}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                        {!captured && <div className={`col-7 col-sm-3 seconds-left`}>
                                            {(<div className={` ${borderColor} border-3 ellipse-frame ${mobileTop}`}>
                                                <img
                                                    id="capturedImageSelf"
                                                    alt=""
                                                />
                                            </div>
                                            )}
                                            {!isMobile &&
                                                <div className="mt-2 text-center">
                                                    {(checkInProgress !== false && <span className={`${textColor} h5 ${blinkLabel}`}>{captureLabel}</span>)}
                                                </div>
                                            }

                                        </div>
                                        }
                                        <div className='mt-4 center'>
                                            {isMobile && checkInProgress && <span className={`text-primary h4 ${blinkLabel} ${textColor}`}>{captureLabel}</span>}
                                        </div>
                                    </div>

                                    <hr className="my-5 mb-3" />
                                    <p className="my-5">&nbsp;</p>
                                    <button className="btn btn-sm btn-outline-secondary rounded-pill position-absolute button-instructions" type="button"
                                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                    >{t('instructions')}
                                    </button>
                                </div>
                                <div className="offcanvas offcanvas-end" id="offcanvasRight"
                                    aria-labelledby="offcanvasRightLabel">
                                    <div className="offcanvas-header">
                                        <h5 id="offcanvasRightLabel">{t('instructions')}</h5>
                                        <Button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></Button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <h5 className="mb-4">{t('instructionTitle')}</h5>
                                        <ol className="mb-4 ps-3">
                                            <li>{t('faceFirstInstruction')}</li>
                                            <li>{t('faceSecondInstruction')}</li>
                                            <li>{t('faceThirdInstruction')}</li>
                                            <li>{t('faceFourthInstruction')}</li>
                                        </ol>
                                        <p className="mb-4">{t('faceExample')}</p>
                                        <img src="/images/face_id.png" className="img-fluid mt-2" />
                                    </div>
                                </div>
                            </main>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Alert variant="danger" className="mt-3">
                    {t("accessDenied")}
                </Alert>
            )}
            <div className="py-2" hidden={!expiredPage}>
                <h4>{t("requestExpired")}</h4>
                <p>{t("accessExpired")}</p>
            </div>
        </>
    )
}

export default FaceIdentification;