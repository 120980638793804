import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
import { t } from "../locale";
import {
    captureSnapshot,
    CameraComponent,
    CameraComponent2,
    imageComparing,
    checkDocumentData,
    saveImage,
    createJob
} from "../helpers/helpers";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from "react-router-dom";

let selfImageData: string;
let documentImageData: string;
let documentImageDataDocumentAnalyze: string;
let countDocCheck = 0;

function Index() {
    const navigate = useNavigate();
    const bottomRef = useRef<HTMLDivElement>(null);

    const pathSegments = window.location.pathname.split("/");
    const companyId = pathSegments[1];
    const requestId = pathSegments[2];
    const passport = pathSegments[4]

    const [hasAccess, setHasAccess] = useState(true);
    const [expiredPage, setExpiredPage] = useState(false);

    const [facingMode, setFacingMode] = useState('environment');
    const [isMobile, setIsMobile] = useState(false);

    const [frontBorderColor, setFrontBorderColor] = useState('border-primary');
    const [secondsLeftFront, setSecondsLeftFront] = useState(10);
    const [timeLabelFront, setTimeLabelFront] = useState(true)
    const [labelWait, setLabelWait] = useState(false)
    const [labelColor, setLabelColor] = useState('text-primary')
    const [label, setLabel] = useState(t('failed'))
    const [textColor, setTextColor] = useState('text-primary')

    const [buttonDisabled, setButtonDisabled] = useState('')
    const [spaceHeight, setSpaceHeight] = useState('mt-2')
    const [captured, setCaptured] = useState(false)
    const [blinkLabel, setBlinkLabel] = useState('')



    const fileInputDocument = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        fetchData();
        handleDeviceDetection();
        if (isMobile) {
            setSpaceHeight('')
        }
    }, [])

    const init = () => {
        setFacingMode('environment')
        setLabelWait(false)
        setTimeLabelFront(true)
        setSecondsLeftFront(10);
    }

    CameraComponent2(facingMode, isMobile);
    CameraComponent(facingMode, isMobile);


    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyId: companyId,
                        requestId: requestId,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();

            if (data) {
                if (data.status === "EXPIRED") {
                    setExpiredPage(false);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasAccess(false);
        }
    }

    const handleDeviceDetection = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        console.log(userAgent);
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
        const isTablet = /(ipad|macintosh|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

        if (isMobile) {
            setIsMobile(true);
        } else if (isTablet) {
            setIsMobile(true)
        }
    }

    const changeCamera = () => {
        setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
    }

    function scrollToBottom() {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }

    const handleDocumentUpload = () => {
        if (fileInputDocument.current) {
            fileInputDocument.current.click(); // Trigger the file input click
        }
    }


    const documentUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setLabelWait(true)
        setLabel(t('wait'))
        setButtonDisabled('disabled')
        const file = event.target.files?.[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                const base64String = reader.result as string
                documentImageDataDocumentAnalyze = base64String.replace(/^data:image\/(jpeg|png);base64,/, '')
                saveImage(documentImageDataDocumentAnalyze, companyId, requestId, false, 'document')
            };
            reader.readAsDataURL(file);

            setCaptured(true)
            resizeImage(file, 800, 600, async (resizedBase64) => {
                const base64String = resizedBase64.replace(/^data:image\/(jpeg|png);base64,/, '')
                documentImageData = base64String
                saveBase64ToLocalStorage('documentImage', documentImageData)
                setTimeLabelFront(false)


                const docImageElement = document.getElementById("imageDocument") as HTMLImageElement
                if (docImageElement) {
                    docImageElement.src = "data:image/png;base64," + resizedBase64

                    docImageElement.style.height = '200px'
                }

                setBlinkLabel('blinking-label')
                setLabel(t('waitDocument'))

                await compareImage()
            });
        }
        scrollToBottom()
    }



    const resizeImage = (file: File, maxWidth: number, maxHeight: number, callback: (base64: string) => void) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                // Proveri proporcije i prilagodi veličinu
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                // Postavi canvas dimenzije
                canvas.width = width;
                canvas.height = height;

                // Iscrtaj sliku na canvas sa smanjenom rezolucijom
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(img, 0, 0, width, height);

                    // Konvertuj canvas nazad u Base64 format
                    const base64String = canvas.toDataURL('image/png'); // Može biti i 'image/png'
                    const imageData = base64String.replace(/^data:image\/png;base64,/, '');
                    callback(imageData);
                }
            };
        };

        reader.readAsDataURL(file);
    }

    const captureFrontId = async () => {

        countDocCheck++;
        setButtonDisabled('disabled')
        const video = document.getElementById("videoElement") as HTMLVideoElement;
        let capturedDocImageElement: HTMLImageElement | null = null;

        if (!isMobile) {
            const countdown = setInterval(() => {
                setSecondsLeftFront(prevSeconds => {
                    if (prevSeconds <= 1) {
                        clearInterval(countdown);
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }

        setCaptured(true)
        setTimeout(async () => {
            if (!isMobile) {

                const result = await captureSnapshot(
                    video,
                    "DOCUMENT",
                    selfImageData,
                    documentImageData
                );
                saveImage(result.documentImageData, companyId, requestId, true, 'document');

                setLabelWait(true)
                setLabel('waitDocument')
                setTimeLabelFront(false)
                scrollToBottom();

                documentImageData = result.documentImageData;
                saveBase64ToLocalStorage('documentImage', documentImageData)
                if (!capturedDocImageElement) {
                    capturedDocImageElement = document.getElementById("imageDocument") as HTMLImageElement;
                }
                if (capturedDocImageElement) {
                    capturedDocImageElement.src = "data:image/png;base64," + documentImageData;
                }

            }

            compareImage()

        }, 10000);
    }

    const saveBase64ToLocalStorage = (key: string, base64: string) => {
        try {
            localStorage.setItem(key, base64);
            console.log('Base64 string saved successfully.');
        } catch (error) {
            console.error('Error saving Base64 string:', error);
        }
    };

    const compareImage = async () => {
        let data;
        if (isMobile) {
            data = await checkDocumentData(documentImageDataDocumentAnalyze, requestId, companyId);
        } else {
            data = await checkDocumentData(documentImageData, requestId, companyId);
        }

        if (data.validation === true) {
            // if (true) {
            changeCamera();
            setFrontBorderColor('border-success');
            setLabelWait(false)
            setTextColor('text-success')
            setLabel(t('success'))
            setTimeLabelFront(false)
            navigate(`/${companyId}/${requestId}/face_identify`);
        } else {
            if (countDocCheck === 2) {
                setFrontBorderColor('border-danger')
                setTextColor('text-danger')
                setLabel(t('fail'))
                setSecondsLeftFront(0);
                setBlinkLabel('')
                setButtonDisabled('')
                return;
            } else {
                setLabel(t('tryAgain'))
                setFrontBorderColor('border-danger')
                setBlinkLabel('')
                setTimeout(() => {
                    init();
                    setButtonDisabled('')
                }, 3000);
            }
        }
    }

    return (
        <>
            <Container className="container-fluid px-sm-5">
                <Col className="col-12 px-0 bg-white d-flex d-sm-none sticky-top z-0">
                    <div className="d-flex flex-row flex-grow-1 align-items-between align-items-sm-start px-2 pe-3 pt-3">
                        <ul className="nav nav-pills flex-row flex-nowrap flex-shrink-1 flex-grow-1 mb-0 justify-content-between align-items-center"
                            id="menu">
                            <li className="nav-item">
                                <a href="index.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 1 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step2.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 2 Active.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step3.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 3 Next.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step4.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 4 Next.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step5.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 5 Next.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Container>
            {hasAccess ? (
                <Container hidden={expiredPage} className={`overflow-hidden`}>
                    <Row className="row overflow-auto">
                        <Col className={`col-12 col-sm-3 col-xl-2 px-0 bg-white d-none d-sm-flex sticky-top pt-4 left-sidebar ${spaceHeight}`}>
                            <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-0 pt-2 text-white">
                                <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
                                    id="menu">
                                    <li className="nav-item mb-4">
                                        <a href={`/${companyId}/${requestId}/p`}
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 1 Done.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-success">{t('personalData')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 2 Active.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-primary">{t('documentVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 3 Next.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-gray-light">{t('faceVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 4 Next.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-gray-light">{t('videoVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 5 Next.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-gray-light">{t('accountVerification')}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        {passport &&
                            (
                                <Col className={`d-flex flex-column h-sm-100 px-sm-5 ${spaceHeight}`}>
                                    <main className={`row overflow-auto px-sm-5 position-relative  ${spaceHeight}`}>
                                        <Col className="pt-4 pt-sm-5 text-center text-sm-start mt-n1">
                                            <h4 className="mb-4">{t('passportTitle')}</h4>

                                            <Row className="mt-6 mt-sm-0">
                                                <div className="col-sm-7  mx-auto mx-sm-0">
                                                    <h5>{t('passportSubTitle')}</h5>
                                                    {!isMobile && <div className={`rounded-3 border ${frontBorderColor} border-3 video-frame w-auto`}>
                                                        {<video className="h-100 w-100 video-element" id="videoElement" autoPlay muted></video>}
                                                        {timeLabelFront && (secondsLeftFront !== 0 && <span className="text-primary h5 mx-0 text-sm-start">{secondsLeftFront}</span>)}
                                                        {labelWait && <span className={`text-primary h5 ${textColor} blinking-label`}>{label}</span>}
                                                    </div>
                                                    }
                                                </div>

                                                <div className="col-sm-3 text-center mt-4 align-content-center">
                                                    <Button className={`btn-primary rounded-pill mt-2 d-none d-sm-inline ${buttonDisabled}`}
                                                        onClick={captureFrontId}>{t('buttonFrontCapture')}</Button>
                                                    <p className="my-2 d-none d-sm-block fs-5">{t('or')}</p>
                                                    <input
                                                        type="file"
                                                        accept="image/*;capture=camera"
                                                        onChange={documentUpload}
                                                        ref={fileInputDocument}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Button className={`btn-primary rounded-pill mt-2 mt-sm-0 ${buttonDisabled}`}
                                                        onClick={handleDocumentUpload}>{t('passportUpload')}</Button>
                                                    <div className="mt-4 mb-4">
                                                        {isMobile && labelWait && <span className={`text-primary h4 ${blinkLabel} ${labelColor}`}>{label}</span>}
                                                    </div>
                                                </div>

                                            </Row>
                                            <Row className="">
                                                <div className="col-sm-7  mx-auto mx-sm-0">
                                                    {captured &&
                                                        <div className={` mt-4 rounded-3 border border-3 video-frame w-auto ${frontBorderColor}`}>
                                                            <img
                                                                id="imageDocument"
                                                                className={`w-100 h-100 object-fit-cover`}
                                                                alt=""
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </Row>
                                            <hr className="my-5 mb-3" />
                                            <p className="my-5">&nbsp;</p>
                                            <button className="btn btn-sm btn-outline-secondary rounded-pill position-absolute button-instructions" type="button"
                                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">{t('instructions')}
                                            </button>
                                        </Col>

                                        <div className="offcanvas offcanvas-end" id="offcanvasRight"
                                            aria-labelledby="offcanvasRightLabel">
                                            <div className="offcanvas-header">
                                                <h5 id="offcanvasRightLabel">{t('instructions')}</h5>
                                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div className="offcanvas-body">
                                                <p className="mb-4">{t('passportInstructionTitle')}</p>
                                                <ul className="text-start list-unstyled mb-4">
                                                    <li>✔️ {t('firstInstruction')}</li>
                                                    <li>✔️ {t('secondInstruction')}</li>
                                                    <li>✔️ {t('thirdInstruction')}</li>
                                                </ul>
                                                <p className="mb-4">{t('example')}</p>
                                                <p className="mb-0">{t('passportInstructions4')}</p>
                                                <img src="/images/Passport.jpg" className="img-fluid mt-2" />
                                            </div>
                                        </div>
                                    </main>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            ) : (
                <Alert variant="danger" className="mt-3">
                    {t("accessDenied")}
                </Alert>
            )}


            <div className="py-2" hidden={!expiredPage}>
                <h4>{t("requestExpired")}</h4>
                <p>{t("accessExpired")}</p>
            </div>
        </>
    );
};

export default Index;