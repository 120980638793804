import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
import { t } from "../locale";
import {
    captureSnapshot,
    CameraComponent,
    saveImage,
    saveVideo,
    createJob
} from "../helpers/helpers";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from "react-router-dom";

function Index() {
    const navigate = useNavigate();

    const [hasAccess, setHasAccess] = useState(true)
    const [expiredPage, setExpiredPage] = useState(false)
    const [facingMode, setFacingMode] = useState('environment')
    const [isMobile, setIsMobile] = useState(false)
    const [recordingLabel, setRecordingLabel] = useState('')
    const [labelColor, setLabelColor] = useState('text-primary')
    const [label, setLabel] = useState(false)
    const [startRecord, setStartRecord] = useState(true)
    const [stopRecord, setStopRecord] = useState(false)
    const [nextButton, setNextButton] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState('')
    const [spaceHeight, setSpaceHeight] = useState('mt-5')
    const fileInputRefVideo = useRef<HTMLInputElement | null>(null)
    const [referenceNumber, setReferenceNumber] = useState('')
    const [videoButtonLabel, setVideoButtonLabel] = useState('')


    const bottomRef = useRef<HTMLDivElement>(null);

    const pathSegments = window.location.pathname.split("/");
    const companyId = pathSegments[1];
    const requestId = pathSegments[2];

    const handleDeviceDetection = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
        const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

        if (isMobile) {
            setIsMobile(true)
            setSpaceHeight('')
            setVideoButtonLabel(t('videoUploadMobile'))
        } else if (isTablet) {
            setIsMobile(true)
            setVideoButtonLabel(t('videoUploadMobile'))
        } else {
            setVideoButtonLabel(t('videoUpload'))
        }
    }

    const handleButtonVideoUpload = () => {
        if (fileInputRefVideo.current) {
            fileInputRefVideo.current.click(); // Trigger the file input click
        }
    };

    const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type.startsWith('video/')) {
                const videoBlob = new Blob([file], { type: file.type });
                saveVideo(videoBlob, companyId, requestId, 'mp4');

                setRecordingLabel('Success')
                setLabel(true)
                setNextButton(true)

            } else {
                console.error('Please upload a valid video file');
            }
        }
    };

    CameraComponent(facingMode, isMobile);

    function scrollToBottom() {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }

    useEffect(() => {
        fetchData();
        handleDeviceDetection();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyId: companyId,
                        requestId: requestId,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();

            if (data) {
                if (data.status === 'EXPIRED') setExpiredPage(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasAccess(false);
        }
    };

    const init = () => {
        setFacingMode('environment')
    }

    const [recording, setRecording] = useState(false);
    const [videoURL, setVideoURL] = useState<string | null>(null);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const recordedChunks = useRef<Blob[]>([]);

    const startRecording = async () => {
        try {
            setStartRecord(false)
            setStopRecord(true)
            setRecordingLabel(t('recording'))
            setLabel(true)
            setButtonDisabled('disabled')
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })

            if (videoRef.current) {
                videoRef.current.srcObject = stream
            }

            const mediaRecorder = new MediaRecorder(stream, { mimeType: "video/webm;codecs=vp8,opus" })
            mediaRecorderRef.current = mediaRecorder

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunks.current.push(event.data)
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(recordedChunks.current, { type: "video/webm" })
                if (blob.size > 0) {
                    const url = URL.createObjectURL(blob)
                    setVideoURL(url)
                    saveVideo(blob, companyId, requestId, 'webm')

                } else {
                    alert("Video file is empty. Please try again.")
                }
                recordedChunks.current = []
            };

            mediaRecorder.start()
            setRecording(true)
        } catch (error) {
            console.error("Error starting video recording:", error)
        }
    };

    const stopRecording = () => {
        setStartRecord(true)
        setStopRecord(false)
        setRecordingLabel(t('success'))
        setLabelColor('text-success')
        setNextButton(true)
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject as MediaStream;
            stream.getTracks().forEach((track) => track.stop());
        }

        setRecording(false);
    };

    const next = () => {
        navigate(`/${companyId}/${requestId}/si/payment/p`)
        createJob(companyId, requestId, setReferenceNumber);
    }

    return (
        <>
            <Container className="container-fluid px-sm-5">
                <Col className="col-12 px-0 bg-white d-flex d-sm-none sticky-top z-0">
                    <div className="d-flex flex-row flex-grow-1 align-items-between align-items-sm-start px-2 pe-3 pt-3">
                        <ul className="nav nav-pills flex-row flex-nowrap flex-shrink-1 flex-grow-1 mb-0 justify-content-between align-items-center"
                            id="menu">
                            <li className="nav-item">
                                <a href="index.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 1 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step2.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 2 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step3.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 3 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step4.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 4 Active.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step5.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 5 Next.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Container>
            {hasAccess ? (
                <Container hidden={expiredPage} className={`overflow-hidden ${spaceHeight}`}>
                    <Row className="row overflow-auto">
                        <Col className={`col-12 col-sm-3 col-xl-2 px-0 bg-white d-none d-sm-flex sticky-top pt-4 left-sidebar ${spaceHeight}`}>
                            <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-0 pt-2 text-white">
                                <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
                                    id="menu">
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 1 Done.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-success">{t('personalData')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 2 Done.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-success">{t('documentVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 3 Done.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-success">{t('faceVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 4 Active.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-primary">{t('videoVerification')}</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a
                                            className="nav-link px-sm-0 px-2">
                                            <img src="/images/logo/Step 5 Next.png"
                                                className="img-fluid step-numbers" />
                                            <span
                                                className="ms-1 d-none d-sm-inline text-gray-light">{t('accountVerification')}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col className={`d-flex flex-column h-sm-100 px-sm-5 ${spaceHeight}`}>
                            <main className="row overflow-auto px-sm-5 position-relative">
                                <div className="col pt-4 pt-sm-5 text-center text-sm-start mt-n1">
                                    <h4 className="mb-4">{t('videoTitle')}</h4>
                                    <div className="row mt-6 mt-sm-0 ">
                                        <h5 className="">{t('videoSubTitle')}</h5>
                                        {
                                            !isMobile && <div className="col-sm-7 text-center mx-auto mx-sm-0">
                                                {/* <!-- This is image holder after upload or for web camera --> */}
                                                <div className="rounded-3 border border-primary border-3 video-frame">
                                                    <video className="h-100 w-100 video-element " id="videoElement" autoPlay muted></video>

                                                </div>
                                                <Row>
                                                    {(label && <span className={`text-primary h5 ${labelColor}`}>{recordingLabel}</span>)}
                                                </Row>
                                            </div>
                                        }
                                        <div className="col-sm-4 text-center mt-2 align-content-center">
                                            {(startRecord && 
                                            <button className={`btn btn-primary rounded-pill mt-2 d-none d-sm-inline`}
                                                onClick={startRecording}
                                                style={{ textTransform: 'none' }}>{t('videoRecord')}</button>
                                            )}
                                            {(stopRecord && <button className={`btn btn-primary rounded-pill mt-2 d-none d-sm-inline `}
                                                onClick={stopRecording}>{t('videoStop')}</button>)}
                                            <p className="my-2 d-none d-sm-block fs-5">{t('or')}</p>
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={handleVideoUpload}
                                                ref={fileInputRefVideo}
                                                style={{ display: 'none' }}
                                            />
                                            <button className={`btn btn-primary rounded-pill mt-2 mt-sm-0 ${buttonDisabled}`}
                                                onClick={handleButtonVideoUpload}
                                                style={{ textTransform: 'none' }}>{videoButtonLabel}</button>
                                            <hr className="my-3" />
                                            {(nextButton && <button
                                                className="btn btn-success btn-lg text-white"
                                                type="submit" onClick={next}>{t('nextStep')} »</button>
                                            )}
                                        </div>
                                    </div>
                                    <hr className="my-5 mb-3" />
                                    <p className="my-5">&nbsp;</p>
                                    <button className="btn btn-sm btn-outline-secondary rounded-pill position-absolute button-instructions" type="button"
                                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                        {t('instructions')}
                                    </button>
                                </div>
                                <div className="offcanvas offcanvas-end" id="offcanvasRight"
                                    aria-labelledby="offcanvasRightLabel">
                                    <div className="offcanvas-header">
                                        <h5 id="offcanvasRightLabel">{t('instructions')}</h5>
                                        <button type="button" className="btn btn-close text-reset" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <h5 className="mb-4">{t('videoInstructionsTitle')}</h5>
                                        <ol className="mb-4">
                                            <li>{t('videoInstructionsFirst')}</li>
                                            <li>{t('videoInstructionsSecond')}</li>
                                            <li>{t('videoInstructionsThird')}</li>
                                            <li>{t('videoInstructionsFourth')}</li>
                                        </ol>
                                        <p className="mb-2">{t('videoExample')}</p>
                                        <img src="/images/video_id.png" className="img-fluid mt-2" />
                                    </div>
                                </div>
                            </main>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Alert variant="danger" className="mt-3">
                    {t("accessDenied")}
                </Alert>
            )}
            <div className="py-2" hidden={!expiredPage}>
                <h4>{t("requestExpired")}</h4>
                <p>{t("accessExpired")}</p>
            </div>
        </>
    )
}

export default Index;