import { useState, useEffect } from "react";
import { t } from "../locale";
import { Alert, Container, Col, Row } from "react-bootstrap";
import { createJob, getReferenceNumber } from "../helpers/helpers";
import { isMobile } from "react-device-detect";


function Payment() {
    const [expiredPage, setExpiredPage] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [jobCreated, setJobCreated] = useState(false);
    const [spaceHeight, setSpaceHeight] = useState('mt-5')

    const pathSegments = window.location.pathname.split("/");
    const companyId = pathSegments[1];
    const requestId = pathSegments[2];


    useEffect(() => {
        fetchData();
        fetchRefNumber();
        if (isMobile) {
            setSpaceHeight('')
        }
    }, []);

    const fetchRefNumber = async () => {
        const refNumber = await getReferenceNumber(companyId, requestId);
        setReferenceNumber(refNumber);
    }

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyId: companyId,
                        requestId: requestId,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();

            if (data) {
                if (data.status === "EXPIRED") {
                    setExpiredPage(false);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasAccess(false);
        }
    };

    return (
        <>
            <Container className="container-fluid px-sm-5">
                <Col className="col-12 px-0 bg-white d-flex d-sm-none sticky-top z-0">
                    <div className="d-flex flex-row flex-grow-1 align-items-between align-items-sm-start px-2 pe-3 pt-3">
                        <ul className="nav nav-pills flex-row flex-nowrap flex-shrink-1 flex-grow-1 mb-0 justify-content-between align-items-center"
                            id="menu">
                            <li className="nav-item">
                                <a href="index.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 1 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step2.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 2 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step3.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 3 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step4.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 4 Done.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                            <li>
                                <a href="step5.html" className="nav-link px-sm-0 px-2">
                                    <img src="/images/logo/Step 5 Active.png" className="img-fluid step-numbers" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Container>
            <div hidden={expiredPage}>
                <div id="paymentInstructions" className="py-3">
                    {hasAccess ? (
                        <Container hidden={expiredPage} className={`overflow-hidden ${spaceHeight}`}>
                            <Row className="row overflow-auto">
                                <Col className={`col-12 col-sm-3 col-xl-2 px-0 bg-white d-none d-sm-flex sticky-top pt-4 left-sidebar ${spaceHeight}`}>
                                    <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-0 pt-2 text-white">
                                        <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
                                            id="menu">
                                            <li className="mb-4">
                                                <a
                                                    className="nav-link px-sm-0 px-2">
                                                    <img src="/images/logo/Step 1 Done.png"
                                                        className="img-fluid step-numbers" />
                                                    <span
                                                        className="ms-1 d-none d-sm-inline text-success">{t('personalData')}</span>
                                                </a>
                                            </li>
                                            <li className="mb-4">
                                                <a
                                                    className="nav-link px-sm-0 px-2">
                                                    <img src="/images/logo/Step 2 Done.png"
                                                        className="img-fluid step-numbers" />
                                                    <span
                                                        className="ms-1 d-none d-sm-inline text-success">{t('documentVerification')}</span>
                                                </a>
                                            </li>
                                            <li className="mb-4">
                                                <a
                                                    className="nav-link px-sm-0 px-2">
                                                    <img src="/images/logo/Step 3 Done.png"
                                                        className="img-fluid step-numbers" />
                                                    <span
                                                        className="ms-1 d-none d-sm-inline text-success">{t('faceVerification')}</span>
                                                </a>
                                            </li>
                                            <li className="mb-4">
                                                <a
                                                    className="nav-link px-sm-0 px-2">
                                                    <img src="/images/logo/Step 4 Done.png"
                                                        className="img-fluid step-numbers" />
                                                    <span
                                                        className="ms-1 d-none d-sm-inline text-success">{t('videoVerification')}</span>
                                                </a>
                                            </li>
                                            <li className="mb-4">
                                                <a
                                                    className="nav-link px-sm-0 px-2">
                                                    <img src="/images/logo/Step 5 Active.png"
                                                        className="img-fluid step-numbers" />
                                                    <span
                                                        className="ms-1 d-none d-sm-inline text-primary">{t('accountVerification')}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <div className={`col d-flex flex-column h-sm-100 ${spaceHeight}`}>
                                    <main className="row ps-sm-5 overflow-auto">
                                        <div className="col-sm-9 ps-sm-5 pt-4 pt-sm-5 mt-sm-n2 mt-3">
                                            <h3 className="mb-3 text-success">{t('paymentTitle')}</h3>

                                            <p>{t('paymentFirst')} <strong>0.01 €</strong> {t('paymentSecond')} <span className="underline">{t('paymentThird')}</span> </p>

                                            <hr className="my-3" />

                                            <h5>{t('paymentFifth')}</h5>

                                            <p className="mb-0"><strong>{t('recipient')}:</strong> DATA Security GmbH</p>
                                            <p className="mb-0"><strong>IBAN:</strong> DE85 7115 0000 0020 2133 77</p>
                                            <p className="mb-0"><strong>{t('paymentStep8')}</strong> {referenceNumber}</p>

                                            <hr className="my-3" />

                                            <p className="fw-bold">{t('sentence1')}</p>
                                            <p>&nbsp;</p>
                                        </div>
                                    </main>
                                </div>
                            </Row>
                        </Container>
                    ) : (
                        <Alert variant="danger" className="mt-3">
                            <div>{t("accessDenied")}</div>
                        </Alert>
                    )}
                </div>
            </div>
            <div className="py-2" hidden={!expiredPage}>
                <h4>{t("requestExpired")}</h4>
                <p>{t("accessExpired")}</p>
            </div>
        </>
    );
}

export default Payment;