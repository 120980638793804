import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { t } from "../locale";

interface PrivacyPolicyModalProps {
    show: boolean;
    onClose: () => void;
    onAccept: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ show, onClose, onAccept }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    return (
        <Modal show={show} onHide={onClose} centered dialogClassName="modal-xl modern-modal">
            <Modal.Header>
                <Modal.Title className="modal-title">{t('privacyPolicyTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body-content">
                    <p>
                        {t('privacyPolicy')}
                        <br />
                        {t('privacyPolicy2')}
                        <br />
                        {t('privacyPolicy3')}
                    </p>
                    <p>
                        {t('privacyPolicy4')} <a
                            href="https://data-security.one/de/datenschutzhinweise"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="privacy-policy-link"
                        >
                            {t('privacyPolicyLink')}
                        </a>
                    </p>
                </div>
                <Form.Group className="checkbox-container">
                    <Form.Check
                        type="checkbox"
                        label={t('accept')}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="checkbox"
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={onAccept}
                    disabled={!isChecked}
                    className="accept-button"
                >
                    {t('acceptGo')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PrivacyPolicyModal;