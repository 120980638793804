import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { t } from "../locale";

interface QRCodeModalProps {
    show: boolean;
    qrCode: any;
    onClose: () => void;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ show, qrCode, onClose }) => {

    return (
        <Modal show={show} onHide={onClose} centered dialogClassName="modal-m modern-modal">
            <Modal.Header>
                <Modal.Title className="modal-title">{t('qrCode')}</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onClose}
                ></button>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-3">
                    <p>Die Identifizierung kann bequem per Smartphone durchgeführt werden, indem Sie diesen QR-Code scannen.</p>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    <img
                        id="backImageDocument"
                        src={qrCode}
                        alt="QR Code"
                        className="img-fluid"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default QRCodeModal;